<script>
    import Request from './Request';

    export default {
        extends: Request,
        name: 'Download',
        props: {
            action: {},
            query: {
                type: Object,
                default: () => ({})
            },
            sensitive: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            query(newValue, oldValue) {
                if (this.sensitive && (JSON.stringify(newValue) !== JSON.stringify(oldValue)) ) {
                    this.request();
                }
            }
        },
        data() {
            return {
                localData: []
            };
        },
        methods: {
            async download() {
                window.location.href = await this.getUrl(this.query);
            }
        }
    }
</script>

<style scoped>

</style>