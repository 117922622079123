<template>
    <div class="button" v-ripple @click="buttonAction">
       <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ButtonBasic',
        methods: {
            buttonAction() {
                this.$emit('buttonAction')
            }
        }
    }
</script>