<script>
    import Request from './Request';

    export default {
        extends: Request,
        name: 'All',
        props: {
            action: {},
            query: {
                type: Object,
                default: () => ({})
            },
            sensitive: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            query(newValue, oldValue) {
                if (this.sensitive && (JSON.stringify(newValue) !== JSON.stringify(oldValue)) ) {
                    this.request();
                }
            }
        },
        data() {
            return {
                localData: []
            };
        },
        methods: {
            async request() {
                try {
                    this.loading = true;

                    const model = await this.getModel();
                    this.localData = await model.all({
                        query: this.query,
                        ...this.config
                    });
                    //console.log(this.localData);

                    this.$emit('update:data', this.localData);
                } catch (error) {
                    // console.log('falla');
                    // this.$router.push({ name: 'Login' }).catch(() => ({}));
                    this.error = error;
                } finally {
                    this.loading = false;
                }
            },
        }
    }
</script>

<style scoped>

</style>