<template>
    <div class="button-secondary" v-ripple @click="buttonAction">
        <slot name="icon"></slot>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ButtonSecondary',
        methods: {
            buttonAction() {
                this.$emit('buttonAction')
            }
        }
    }
</script>